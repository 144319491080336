import React, { useEffect, useState } from "react";
import { isAdBlockerActive } from "../functions/utils";

const withAdBlockerDetection = (WrappedComponent, FallbackComponent) => {
  return (props) => {
    const [adBlockerDetected, setAdBlockerDetected] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const checkAdBlocker = async () => {
        const isActive = await isAdBlockerActive();
        setAdBlockerDetected(isActive);
        setLoading(false);
      };

      checkAdBlocker();
    }, []);

    if (loading) {
      return <div>Loading...</div>; // Optional: a loading state while checking
    }

    if (adBlockerDetected) {
      return <FallbackComponent {...props} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAdBlockerDetection;
