import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import routes from "./routes";
import PublicRoute from "./routes/PublicRoute";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import withAdBlockerDetection from "./components/withAdBlockerDetection";
import FallbackUI from "./components/adBlockerFallbackUI";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((m, i) => (
          <PublicRoute key={i} {...m} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default withAdBlockerDetection(App, FallbackUI);
