import React, { Suspense } from "react";
import Sidebar from "./components/Sidebar";
import SidebarMobile from "./components/SidebarMobile";
import Header from "./components/Header";
import Loading from "../components/common/Loading";

const LazyLoading = () => (
  <div className="flex justify-center items-center h-screen w-full bg-white dark:bg-gray-900">
    <Loading />
  </div>
);

const WithSidebar = (props) => {
  const { children } = props;
  const {
    props: { title, subtitle },
  } = children;

  return (
    <div className="min-h-screen flex bg-body-bg dark:bg-body-dark-bg font-Inter">
      <Sidebar {...props} />
      <SidebarMobile {...props} />
      <div className="sm:ml-0 lg:ml-left flex-1 mx-auto w-full flex flex-col">
        <Header {...props} {...{ title, subtitle }} />
        <main
          // className={classNames(
          //   subtitle ? 'lg:mt-36 md:mt-16 mt-14' : 'lg:mt-24 md:mt-16 mt-14',
          //   `flex-1 relative focus:outline-none ${mobileBgImg} ${bgImg} ${bgImgClass}`,
          // )}
          className="mb-8"
        >
          <div className="min-h-full-9 md:min-h-full-3">
            <Suspense fallback={<LazyLoading />}>
              <div className="">{children}</div>
            </Suspense>
          </div>
          {/* <Footer {...props} /> */}
        </main>
      </div>
    </div>
  );
};

export default WithSidebar;
