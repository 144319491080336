import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
// import HeaderCTAs from '../../components/common/HeaderCTAs';
import coverComparedLogo from "../../assets/img/Mega Pot.png";
import CoverCompareDarkLogo from "../../assets/img/Mega Pot.png";
import { ThemeContext } from "../../themeContext";
import HeaderMobile from "./HeaderMobile";
import { Menu as MaterialMenu, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Button from "../../components/common/Button";
import useAuth from "../../hooks/useAuth";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderNoSidebar = (props) => {
  const { theme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { isAuthenticated, user, logout } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  return (
    <>
      <HeaderMobile {...props} />
      <div className="z-30 flex-shrink-0 bg-white dark:bg-body-dark-bg py-4 fixed w-full hidden lg:flex">
        <div className="flex-1 px-10">
          <div className="flex justify-between">
            <Link to="/">
              <img
                className="h-8"
                src={
                  theme === "light" ? coverComparedLogo : CoverCompareDarkLogo
                }
                alt="Workflow"
              />
            </Link>
            <div className="flex justify-between">
              <span
                className="mx-4 cursor-pointer hover:text-primary self-center"
                id="games-menu"
                aria-controls={open ? "games-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Games <KeyboardArrowDown fontSize="sm" />
                <MaterialMenu
                  id="games-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Link to="/">Lottery</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to="/scratch-card">Scratch Card</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to="/result-list">Results</Link>
                  </MenuItem>
                </MaterialMenu>
              </span>
              <span className="mx-4 hover:text-primary cursor-pointer self-center">
                <Link to="/game-guide">Game Guide</Link>
              </span>
              <span className="mx-4 hover:text-primary cursor-pointer self-center">
                <Link to="/contact-us">Contact Us</Link>
              </span>
            </div>
            <div className="flex justify-between">
              {isAuthenticated ? (
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                        <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                          <span className="sr-only">Open user menu for </span>
                          {user.name}
                        </span>
                        <ChevronDownIcon
                          className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/profile"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/"
                              onClick={() => {
                                logout();
                              }}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Logout
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : (
                <>
                  <Link to="/login">
                    <Button variant="outlined" className="mx-2">
                      Login
                    </Button>
                  </Link>
                  <Link to="/sign-up">
                    <Button variant="contained">Create Account</Button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNoSidebar;
