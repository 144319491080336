import React from "react";
import GuestGuard from "../components/GuestGuard";
// import AuthGuard from "../components/AuthGuard";

const Lottery = React.lazy(() => import("../views/Lottery"));
const ScratchCard = React.lazy(() => import("../views/ScratchCard"));
const ResultList = React.lazy(() => import("../views/Results"));
const Login = React.lazy(() => import("../views/Auth/Login"));
const Signup = React.lazy(() => import("../views/Auth/Signup"));
const ErrorPage = React.lazy(() => import("../views/ErrorPage"));
const ContactUs = React.lazy(() => import("../views/ContactUs"));
const GameGuide = React.lazy(() => import("../views/GameGuide"));
const Profile = React.lazy(() => import("../views/Profile"));
const ForgotPassword = React.lazy(() => import("../views/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../views/Auth/ResetPassword"));

const routes = [
  {
    path: "/contact-us",
    pageName: "Contact Us",
    exact: true,
    withSidebar: false,
    component: ContactUs,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/profile",
    pageName: "Profile",
    exact: true,
    withSidebar: false,
    component: Profile,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/",
    pageName: "Lottery",
    exact: true,
    withSidebar: false,
    component: Lottery,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/scratch-card",
    pageName: "Scratch Card",
    exact: true,
    withSidebar: false,
    component: ScratchCard,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/result-list",
    pageName: "Results",
    exact: true,
    withSidebar: false,
    component: ResultList,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/game-guide",
    pageName: "Game Guide",
    exact: true,
    withSidebar: false,
    component: GameGuide,
    guard: GuestGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/login",
    pageName: "Login",
    exact: true,
    guard: GuestGuard,
    component: Login,
    title: "",
    subtitle: "",
  },
  {
    path: "/sign-up",
    pageName: "Signup",
    exact: true,
    guard: GuestGuard,
    component: Signup,
    title: "",
    subtitle: "",
  },
  {
    path: "/forgot-password",
    pageName: "Forgot Password",
    exact: true,
    guard: GuestGuard,
    component: ForgotPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "/reset-password",
    pageName: "Reset Password",
    exact: true,
    guard: GuestGuard,
    component: ResetPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "",
    pageName: "Error",
    withSidebar: false,
    component: ErrorPage,
    title: "404",
    bgImg: "md:bg-commonSectionBg",
    mobileBgImg: "bg-Mobile404Bg",
    bgImgClass: "bg-100% bg-no-repeat bg-bottom",
  },
];

export default routes;
