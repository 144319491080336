import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import SplashScreen from "../components/common/Loading";
import { BASEURL } from "../constants";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "UPDATEPROFILE": {
      window.localStorage.setItem(
        "user",
        JSON.stringify({ ...state.user, ...action.payload })
      );
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  updateProfile: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const response = await axios.post(
      `${BASEURL}/auth/login`,
      {
        email,
        password,
        adminPortal: false,
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...response.data.user, token: response.data.token })
    );
    dispatch({
      type: "LOGIN",
      payload: {
        user: { ...response.data.user, token: response.data.token },
      },
    });
    return response;
  };

  const logout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  const updateProfile = (data) => {
    dispatch({ type: "UPDATEPROFILE", payload: data });
  };

  const register = async ({
    firstName,
    lastName,
    email,
    wallet,
    phoneNumber,
    password,
  }) => {
    const response = await axios.post(`${BASEURL}/auth/register`, {
      firstName,
      lastName,
      email,
      walletAddress: wallet,
      phoneNumber,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
    return response;
  };
  const getUser = () => {
    return JSON.parse(window.localStorage.getItem("user"));
  };
  useEffect(() => {
    const initialise = async () => {
      try {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user) {
          // setSession(user);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          window.localStorage.clear();
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        window.localStorage.clear();
      }
    };
    initialise();
  }, []);
  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        getUser,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
