import { useHistory } from "react-router";
import {
  BellIcon,
  ChatIcon,
  ClipboardListIcon,
  CreditCardIcon,
  GiftIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ReactComponent as HomeIcon } from "../../assets/img/home-icon.svg";

const nav = [
  { name: "Lottery", to: "/", icon: HomeIcon, authProtected: false },
  {
    name: "Scratch Card",
    to: "/scratch-card",
    icon: UserIcon,
    authProtected: false,
  },
  {
    name: "Results",
    to: "/result-list",
    icon: ClipboardListIcon,
    authProtected: false,
  },
  {
    name: "Game Guide",
    to: "/game-guide",
    icon: CreditCardIcon,
    authProtected: false,
  },
  {
    name: "Contact Us",
    to: "/contact-us",
    icon: GiftIcon,
    authProtected: false,
  },
  {
    name: "Login",
    to: "/login",
    icon: BellIcon,
    authProtected: false,
  },
  {
    name: "Sign Up",
    to: "/sign-up",
    icon: ChatIcon,
    authProtected: false,
  },
];

const Navigation = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return nav.map((m) => ({ ...m, current: m.to === pathname }));
};

export default Navigation;
