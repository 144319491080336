import { useLocation } from "react-router-dom";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const isObject = (a) => {
  return !!a && a.constructor === Object;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const numberFormat = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export function numberToWords(num) {
  if (!num) return "";

  const words = [
    { value: 1e9, str: "Billion" },
    { value: 1e6, str: "Million" },
    { value: 1e3, str: "Thousand" },
    { value: 1e2, str: "Hundred" },
  ];

  for (let i = 0; i < words.length; i++) {
    if (num >= words[i].value) {
      return (num / words[i].value).toFixed(1) + " " + words[i].str + " $";
    }
  }
  return num.toString() + " $";
}

export const isAdBlockerActive = async () => {
  try {
    await fetch(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
      {
        method: "HEAD",
        mode: "no-cors",
      }
    );
    return false; // If the fetch is successful, no ad blocker is detected
  } catch (e) {
    return true; // If the fetch fails, an ad blocker is likely active
  }
};
