import React from "react";

const Button = ({
  type = "button",
  variant,
  className = "",
  color = "primary",
  children,
  disabled,
  ...rest
}) => {
  const getColorStyles = () => {
    switch (color) {
      case "primary":
        return {
          bg: "bg-primary",
          border: "border border-primary",
          text: "text-primary",
        };
      case "secondary":
        return { bg: "bg-green", border: "border-green", text: "text-green" };
      default:
        return {
          bg: "bg-primary",
          border: "border border-primary",
          text: "text-primary",
        };
    }
  };

  const { bg, border, text } = getColorStyles();

  const getVariantStyles = () => {
    switch (variant) {
      case "contained":
        return {
          bg: bg,
          text: "text-white",
          hoverBg: `hover:bg-dark-${bg.replace("bg-", "")}`,
        };
      default:
        return {
          bg: "bg-white",
          text: text,
          border: border,
          hoverBg: `hover:bg-dark-primary`,
          hoverText: "hover:text-white",
          focusText: "focus:text-white",
        };
    }
  };

  let {
    hoverBg,
    hoverText,
    focusText,
    bg: variantBg,
    text: variantText,
    border: variantBorder,
  } = getVariantStyles();

  if (disabled) {
    variantBg = "bg-gray-500";
    hoverBg = "hover:" + variantBg;
    hoverText = "hover:text-dark";
    focusText = "focus:text-dark";
    variantText = "text-dark";
    variantBorder = "border-none";
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={`inline-flex font-Manrope items-center justify-center rounded-full px-6 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${className} ${variantBg} ${variantText} ${variantBorder} ${hoverBg} ${hoverText} ${focusText}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
